/* eslint no-label-var: "off" */
import { createBrowserHistory } from 'history';
import React, { useEffect, useMemo, useState } from 'react';
/** Components */
import Layout from './layout';
import LayoutWide from './layout/layoutWide';
import LayoutAuth from './layout/auth';
import LayoutAuthUp from './layout/authUp';
import LayoutAuthReverse from './layout/authReverse';
import LayoutAuthWide from './layout/authWide';
import LayoutAuthWideFooter from './layout/authWideFooter';
import Report from './views/report';
import { useTranslation, withTranslation, Trans } from 'react-i18next';
import './i18n';
import { useSelector, useDispatch } from 'react-redux';
/** Styles in pures CSS */
import './App.css';
import './assets/font-awesome/css/all.css';
import './assets/styles/index.css';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from 'react-router-dom';
import { ganttAPI } from './utils/customGanttPlugin';
import { socket } from './services/socket.service';
import * as projectActions from './redux/slices/projectSlice';
import { propertiesActions } from './redux/actions/propertiesAction';
import { companySettingsAction } from './redux/actions/companySettingsAction';
import { sectorService } from './services/sector.service';
import { companyService } from './services/company.service';
import { permissionService } from './services/permission.service';
import { compareValues } from './utils';
import { message } from 'antd';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Modal, Row } from 'antd';
import { base } from './services/base';
import { initAnalytics, userPropertiesAnalytics } from './analytics';
import { propertiesService } from './services/properties.service';
import { AMPLITUDE_SERVICE, TOTANGO_SERVICE } from './analytics/constants';
import userSettingsService from './services/userSettings.service';
import companySettingsService from './services/companySettings.service';
import {
  setViews,
  setGanttLoading,
  setInitialGanttState
} from './redux/slices/ganttSlice';
import { defaultViewService } from './services/defaultViews.service';
import { setupDynamicHubSpotStyles } from './utils/hubSpotStyles';
import { useWarningForLowerEnvs } from './hooks/useWarningForLowerEnvs';
import { useCheckActualSector } from './hooks/useCheckActualSector';
import { userService } from './services';
import BaseLoader from './layout/loader/base';
import { companyActions } from './redux/actions/companyActions';
import { useLogout } from './hooks/useLogout';
import { getSessionTokenData, getSignedUser } from './utils/userUtils';
import * as Sentry from '@sentry/react';
import SSOProcoreWebview from './views/login/SSOProcoreWebview';
import {
  ACCESS_GRANTED,
  INVALID_LINK,
  LOADING_ACCESS,
  NO_MODULE_ACCESS,
  NO_ORGANIZATION_ACCESS,
  NO_SCHEDULE_ACCESS,
  NOT_FOUND
} from './constants/access';
import { useInitializeSession } from './hooks/useInitializeSession';
import InvalidLinkLayout from './layout/invalidLink';
import { exactRouteList, partialRouteList } from './router/routes';
import { useCheckUserAndLocation } from './hooks/useCheckUserAndLocation';

const SentryRoute = Sentry.withSentryRouting(Route);

// Create a client
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: 1000 * 60 * 0.5,
      staleTime: 1000 * 60 * 0.5,
      refetchOnWindowFocus: false
    }
  }
});

const signed = localStorage.getItem('signed');
const DEFAULT_MONTH_ZOOM_LABEL = JSON.stringify('month');

function LayoutRender({ children }) {
  const signed = localStorage.getItem('signed');
  const LayoutComponent = signed ? Layout : LayoutAuth;
  return <LayoutComponent>{children}</LayoutComponent>;
}

const DynamicLayout = ({ children }) => {
  useCheckUserAndLocation();
  const { status } = useInitializeSession();
  const component = useMemo(() => {
    const componentByStatus = {
      [LOADING_ACCESS]: null,
      [NOT_FOUND]: <InvalidLinkLayout type={NOT_FOUND} />,
      [NO_ORGANIZATION_ACCESS]: (
        <InvalidLinkLayout type={NO_ORGANIZATION_ACCESS} />
      ),
      [NO_SCHEDULE_ACCESS]: <InvalidLinkLayout type={NO_SCHEDULE_ACCESS} />,
      [NO_MODULE_ACCESS]: (
        <Layout>
          <InvalidLinkLayout type={NO_MODULE_ACCESS} />
        </Layout>
      ),
      [ACCESS_GRANTED]: <Layout />
    };

    return componentByStatus[status];
  }, [status]);

  return component;
};

function isDefaultViewEmpty(defaultView) {
  return Array.isArray(defaultView);
}

function filterDefaultView(defaultView) {
  const DEFAULT_VIEW_ID = defaultView.defaultView.id;
  const DEFAULT_SCHEDULE_VIEW = defaultView.scheduleViews.find(
    (view) => view.id === DEFAULT_VIEW_ID
  );
  return DEFAULT_SCHEDULE_VIEW;
}

function App(props) {
  const { t } = props;
  window.translateObject = t;
  const projectState = useSelector((state) => state.projectState);
  const dispatch = useDispatch();
  useLogout();
  useWarningForLowerEnvs();
  const { loading } = useCheckActualSector(userService);

  useEffect(() => {
    const asyncF = async (_) => {
      const { allSectors } = projectState;
      if (allSectors) {
        const sector = await sectorService.show(projectState.sectorSelected);
        if (sector && sector.sector) {
          dispatch(setInitialGanttState());
          window.localStorage.setItem('scale', DEFAULT_MONTH_ZOOM_LABEL);
          const currentSector = sector.sector;
          const copy_sector = { ...currentSector };
          copy_sector.cncs = [];
          copy_sector.constraints = [];
          copy_sector.weekcommitments = [];
          delete copy_sector.activities;
          delete copy_sector.activityrelations;
          sessionStorage.setItem('currentSector', JSON.stringify(copy_sector));
          ganttAPI.updateFormatter();
          const defaultView = await defaultViewService.getDefaultView(
            projectState.sectorSelected
          );
          if (isDefaultViewEmpty(defaultView)) {
            resetDefaultViewTemplateView();
            return;
          }
          const defaultViewTemplate = filterDefaultView(defaultView);
          if (defaultViewTemplate) {
            dispatch(
              setViews({
                ...defaultViewTemplate
              })
            );
          }
        }
      }
    };
    resetDefaultViewTemplateView();
    setGanttToLoading();
    asyncF();
  }, [projectState.sectorSelected]);

  function resetDefaultViewTemplateView() {
    dispatch(setViews({}));
  }

  function setGanttToLoading() {
    dispatch(setGanttLoading(true));
  }
  useEffect(() => {
    if (projectState.allProjects) {
      const project = projectState.allProjects.find(
        (e) => e.id == projectState.projectSelected
      );
      if (project) {
        const copy_project = { ...project };
        copy_project.sectors = [];
        sessionStorage.setItem('currentProject', JSON.stringify(copy_project));
      }
    }
  }, [projectState.projectSelected]);

  async function getSectors() {
    const resp = await sectorService.index();
    return resp ? resp.sectors : false;
  }

  useEffect(() => {
    setPermitionRefresh();
  }, []);

  useEffect(() => {
    const companySelected = sessionStorage.getItem('company');
    if (!companySelected) return;

    const currentUser = getSignedUser();
    if (!currentUser) return;

    handleUserPropertiesAnalytics(currentUser);
    window.Appcues.identify(currentUser.id, {
      email: currentUser.email,
      firstName: currentUser.name,
      createdAt: currentUser.createdAt,
      role: currentUser.role
    });
    window.Appcues.page();
  });

  useEffect(() => {
    initAnalytics();
  }, []);

  const setPermitionRefresh = async () => {
    const permissions = await permissionService.getPermissionsByUsers();
    if (!permissions.permissionTable) return;
    sessionStorage.setItem(
      'permissiontable',
      JSON.stringify(permissions.permissionTable)
    );
  };

  const handleUserPropertiesAnalytics = async (user) => {
    try {
      const { company } = await companyService.show(user?.companyId);
      dispatch(companyActions.setCurrentCompany(company));
      const currentUser = {
        ...user,
        company: company?.name
      };
      let transferResponsible;

      const { constraintValidation } =
        (await userSettingsService.getConstraintValidation(user?.id)) || null;
      if (company) {
        transferResponsible =
          (await companySettingsService.getTransferResponsibleSetting(
            company?.id
          )) || null;
        dispatch(
          companySettingsAction.setTransferResponsibles(transferResponsible)
        );
      }

      sessionStorage.setItem('company', JSON.stringify(company));
      if (constraintValidation !== undefined) {
        localStorage.setItem(
          'constraintValidation',
          JSON.stringify(constraintValidation)
        );
      }
      userPropertiesAnalytics(currentUser, AMPLITUDE_SERVICE);
      userPropertiesAnalytics(currentUser, TOTANGO_SERVICE);
    } catch (err) {
      console.log(`Error getCompanyService, Error: ${err}`);
    }
  };

  const refreshSectors = async (reload = true) => {
    if (reload)
      message.info(
        'Alguien ha actualizado el criterio de horas por dia, actualizando...'
      );
    const sectors = await getSectors();

    if (sectors) {
      const filterSectors = sectors.filter(
        (e) => e.projectId === projectState.projectSelected && e.status === true
      );
      filterSectors.sort(compareValues('order'));

      /** update state sectors */
      dispatch(projectActions.setAllSectors(filterSectors));

      if (reload) {
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }
    }
  };

  useEffect(() => {
    socket.on('hours_updated_' + projectState.sectorSelected, refreshSectors);
    return () => {
      socket.off(
        'hours_updated_' + projectState.sectorSelected,
        refreshSectors
      );
    };
  }, [projectState.sectorSelected]);

  useEffect(() => {
    const cleanup = setupDynamicHubSpotStyles(window.location.pathname);
    return () => cleanup();
  }, [window.location.pathname]);

  const history = createBrowserHistory();

  return loading ? (
    <BaseLoader />
  ) : (
    <QueryClientProvider client={queryClient}>
      <Router history={history}>
        <Switch>
          <SentryRoute path="/reportlookahead/week/allNewTab">
            <Layout withoutLayout={true} />
          </SentryRoute>
          <SentryRoute path="/register">
            <LayoutAuth />
          </SentryRoute>
          <SentryRoute path={['/login']}>
            <LayoutAuthReverse />
          </SentryRoute>
          <SentryRoute path="/sso/login">
            <SSOProcoreWebview />
          </SentryRoute>
          <SentryRoute path={['/forgot']}>
            <LayoutAuthReverse />
          </SentryRoute>
          <SentryRoute path={['/signup', '/signupconfirmation']}>
            <LayoutAuthReverse />
          </SentryRoute>
          <SentryRoute path={['/selectCompany']}>
            <LayoutAuthReverse />
          </SentryRoute>
          <SentryRoute path={['/confirmation', '/changepassword']}>
            <LayoutAuthWide />
          </SentryRoute>
          <SentryRoute
            path={['/company', '/confirmationproject', '/invite', '/stages']}>
            <LayoutAuthWideFooter />
          </SentryRoute>
          <SentryRoute
            path={[
              '/:organizationId/project/:projectId/schedule/:scheduleId/export/weekly'
            ]}>
            <DynamicLayout />
          </SentryRoute>
          <SentryRoute exact path="/projects">
            <LayoutWide />
          </SentryRoute>
          <SentryRoute exact path="/">
            <LayoutAuthReverse />
          </SentryRoute>
          <SentryRoute exact path="/weekreport">
            <Report />
          </SentryRoute>
          <SentryRoute path="/weeklyplan/planning">
            <Redirect to={{ pathname: '/weeklyplan/commintments' }} />
          </SentryRoute>
          <SentryRoute path={partialRouteList}>
            <LayoutRender />
          </SentryRoute>
          <SentryRoute exact path={exactRouteList}>
            <LayoutRender />
          </SentryRoute>
          <SentryRoute path="*">
            <InvalidLinkLayout type={INVALID_LINK} />
          </SentryRoute>
        </Switch>
      </Router>
    </QueryClientProvider>
  );
}

export default withTranslation()(App);
